import './Logo.scss'

export const Logo = () => (
    <div className='wrapper'>
        <div className='snow'></div>
        <div className='snow'></div>
        <div className='snow'></div>
        <div className='snow'></div>
        <div className='snow'></div>
        <div className='snow'></div>
        <div className='snow'></div>
        <div className='snow'></div>
        <div className='snow'></div>
        <div className='snow'></div>
        <div className='snow'></div>
        <div className='snow'></div>
        <div className='snow'></div>
        <div className='snow'></div>      
        <div className='snow'></div>
        <div className='snow'></div>
        <div className='snow'></div>
        <div className='snow'></div>
        <div className='snow'></div>
        <div className='snow'></div>        
        <div className='snow'></div>
        <div className='snow'></div>
        <div className='snow'></div>
        <div className='snow'></div>
        <div className='snow'></div>
        <div className='snow'></div> 
        <div className='snow'></div>
        <div className='snow'></div>
        <div className='snow'></div>
        <div className='snow'></div>
        <div className='snow'></div>
        <div className='snow'></div>
        <div className='snow'></div>
        <div className='snow'></div>
        <div className='snow'></div>
        <div className='snow'></div>
        <div className='snow'></div> 
        <div className='snow'></div>
        <div className='snow'></div>
        <div className='snow'></div>
        <div className='snow'></div>
        <div className='snow'></div>
        <div className='snow'></div>
        <div className='snow'></div>
        <div className='snow'></div>
        <div className='snow'></div>
        <div className='snow'></div>
        <div className='snow'></div> 
        <div className='snow'></div>
        <div className='snow'></div>
        <div className='snow'></div>
        <div className='snow'></div>
        <div className='snow'></div>
        <div className='snow'></div>
        <div className='snow'></div>
        <div className='snow'></div>
        <div className='snow'></div>
        <div className='snow'></div>
        <div className='snow'></div> 
        <div className='snow'></div>
        <div className='snow'></div>
        <div className='snow'></div>
        <div className='snow'></div>
        <div className='snow'></div>
        <div className='snow'></div>
    </div>
)