import { Home } from './pages/Home';

function App() {
  return (
    <>
     <Home />
    </>
  );
}

export default App;
